import React, { useState, useEffect, useRef } from 'react';
import DeleteModal from '../../components/DeleteModal';
import { PropertyData, ScheduleSection, MapSection } from '../../components/Property/Details'
import {
  Container,
  Tooltip,
  Box,
  CircularProgress,
  Grid,
  Card,
  CardContent,
  IconButton,
  Typography
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { useParams, useNavigate } from 'react-router-dom';
import { getPropertyById, deleteProperty } from '../../utils/propertyUtils';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const PropertyDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [property, setProperty] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [deleteLoading, setDeleteLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [mapHeight, setMapHeight] = useState(0);
  const [schedule, setSchedule] = useState();
  const infoBoxRef = useRef(null);

  useEffect(() => {
    const fetchProperty = async () => {
      try {
        setLoading(true); // Show loading spinner
        const propertyData = await getPropertyById(id);
        setProperty(propertyData);
      } catch (error) {
        setError('Error fetching property data');
        toast.error('Propiedad no encontrada.');
        navigate('/my-properties'); // Redirect on error
      } finally {
        setLoading(false); // Hide loading spinner after the fetch
      }
    };

    fetchProperty();
  }, [id, navigate]);

  useEffect(() => {
    if (infoBoxRef.current) {
      setMapHeight(infoBoxRef.current.clientHeight);
    }
  }, [property]);

  if (loading) {
    return <CircularProgress />; // Show a loading spinner while data is being fetched
  }

  if (error) {
    return <p>{error}</p>; // Display error message
  }

  if (!property) {
    navigate('/my-properties');
    return null; // Return null if no property data
  }
  const state = "Antioquia";
  const country = "Colombia"
  const googleMapsUrl = `https://www.google.com/maps?q=${encodeURIComponent(property.city)}%2C%20${encodeURIComponent(property.neighborhood)}%2C%20${state}%2C%20${country}&z=15&output=embed`;

  const handleDeleteClick = () => {
    setDeleteId(id);
    setShowModal(true);
  };

  const handleConfirmDelete = async () => {
    setDeleteLoading(true);
    try {
      deleteProperty(deleteId);
      navigate('/my-properties');
      toast.success('Propredad eliminada con éxito!');
    } catch (error) {
      toast.error('Error al eliminar Propiedad.');
    } finally {
      setDeleteLoading(false);
      setShowModal(false);
    }
  };

  const handleCancelDelete = () => {
    setDeleteId(null);
    setShowModal(false);
  };

  const handleCancelMeeting = () => {
    setSchedule(null);
  };

  return (
    <>
      <Container sx={{ padding: 2 }}>
        <Grid container spacing={3} alignItems="stretch">
          <Grid item xs={12} md={7} sx={{
            height: {
              xs: 750,  // For extra small screens
              sm: 530,  // For small screens
              md: 530   // For medium and larger screens
            }
          }}>
            <Card
              sx={{
                boxShadow: 3,
                borderRadius: 2,
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                position: 'relative',
              }}
            >
              <CardContent ref={infoBoxRef} sx={{ flex: 1 }}>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    mb: 3,
                    backgroundColor: '#1B447E',
                    padding: 2,
                    borderRadius: 1,
                  }}
                >
                  <Typography
                    variant="h5"
                    gutterBottom
                    align="center"
                    sx={{ color: '#FFFFFF' }}
                  >
                    Detalles de la Propiedad
                  </Typography>
                </Box>
                <PropertyData property={property} />
                {/* <EarningsTable property={property} /> */}
                <ScheduleSection
                  schedule={schedule}
                  onCancelMeeting={handleCancelMeeting}
                  propertyId={id}
                />
              </CardContent>

              <Box
                sx={{
                  position: 'absolute',
                  top: 23,
                  right: 23,
                  zIndex: 1,
                }}
              >
                <Box
                  sx={{
                    backgroundColor: '#FFFFFF',
                    borderRadius: '100%',
                    padding: 1,
                    boxShadow: 1,
                  }}
                >
                  <Tooltip title="Eliminar">
                    <IconButton
                      color="error"
                      onClick={handleDeleteClick}
                      disabled={deleteLoading}
                      aria-label="Eliminar"
                    >
                      {deleteLoading ? <CircularProgress size={24} /> : <DeleteIcon />}
                    </IconButton>
                  </Tooltip>
                </Box>
              </Box>
            </Card>
          </Grid>

          <Grid item xs={12} md={5}>
            <MapSection googleMapsUrl={googleMapsUrl} mapHeight={mapHeight} />
          </Grid>
        </Grid>

        <DeleteModal
          open={showModal}
          onClose={handleCancelDelete}
          onConfirm={handleConfirmDelete}
        />

        <ToastContainer />
      </Container>
    </>
  );
};

export default PropertyDetails;
