import React, { useState, useEffect, useContext } from 'react';
import { Container, TextField, Button, Link, Box, Typography, Alert } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import LoginWithGoogle from '../components/GoogleLogin';
import { AuthContext } from '../context/AuthContext';

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const { isAuthenticated } = useContext(AuthContext);

  useEffect(() => {
    if (isAuthenticated) {
      navigate('/');
    }
  }, [navigate, isAuthenticated]);

  const handleLogin = async (e) => {
    e.preventDefault();
    setError('');
    //Implement
    
  };

  return (
    <Container maxWidth="xs" sx={{ mt: 5 }}>
      <Typography variant="h4" sx={{ mb: 3, textAlign: 'center', fontWeight: 'bold' }}>
        Iniciar Sesión
      </Typography>

      {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}

      <Box sx={{ textAlign: 'center', mb: 2 }}>
        <LoginWithGoogle sx={{ display: 'inline-block', width: '100%' }} />
      </Box>

      <Box sx={{ my: 2, textAlign: 'center' }}>
        <hr style={{ border: '1px solid #ccc', width: '100%', margin: '1rem 0' }} />
      </Box>

      <form onSubmit={handleLogin}>
        <TextField
          label="Correo Electrónico"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          fullWidth
          required
          margin="normal"
          variant="outlined"
        />
        <TextField
          label="Contraseña"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          fullWidth
          required
          margin="normal"
          variant="outlined"
        />

        <Button type="submit" variant="contained" color="primary" fullWidth sx={{ mt: 2 }}>
          Iniciar Sesión
        </Button>
      </form>

      <Box sx={{ mt: 2, textAlign: 'center' }}>
        <Link href="/forgot-password" variant="body2">¿Olvidaste tu contraseña?</Link>
      </Box>
      <Box sx={{ mt: 1, textAlign: 'center' }}>
        <Link href="/signup" variant="body2">Crear una cuenta nueva</Link>
      </Box>
    </Container>
  );
}

export default Login;
