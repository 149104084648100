export const getProperties = () => {
    const token = localStorage.getItem('token');

    return fetch(`${process.env.REACT_APP_API_URL}/Properties/GetProperties`, {
        method: 'GET',
        credentials: 'include',
        headers: {
            'Authorization': `Bearer ${token}`
        }
    })
        .then(response => {
            if (response.ok)
                return response.json()
        })
        .then(properties => {
            return properties;
        })
        .catch(error => {
            console.error('Error fetching properties:', error);
            throw error;
        });
};


export const getPropertyById = async (id) => {
    const token = localStorage.getItem('token');
    
    const propertyResponse = await fetch(`${process.env.REACT_APP_API_URL}/Properties/GetPropertyDetail/${id}`,
        {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
    const property = await propertyResponse.json();
    return property;
};

export const deleteProperty = (id) => {

};