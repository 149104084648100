import React, { useState, useEffect } from 'react';
import {
  Grid,
  Container,
  Button,
  Box,
  Typography,
  Modal,
  Alert,
  Card,
  CardContent,
  Tabs,
  Tab,
} from '@mui/material';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AirbnbLinkInput from '../../components/Property/Register/AirbnbLinkInput';
import PropertyRegistrationForm from '../../components/Property/Register/PropertyRegistrationForm';

const initialCities = [];
const initialNeighborhoods = [];

function RegisterProperty() {
  const [ciudad, setCiudad] = useState('');
  const [nickname, setNickname] = useState('');
  const [barrio, setBarrio] = useState('');
  const [guests, setGuests] = useState('');
  const [estimatedValue, setEstimatedValue] = useState(0);
  const [bedrooms, setBedrooms] = useState('');
  const [baths, setBaths] = useState('');
  const [beds, setBeds] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [error, setError] = useState('');
  const [newProperty, setNewProperty] = useState(null);
  const [cities, setCities] = useState(initialCities);
  const [neighborhoods, setNeighborhoods] = useState(initialNeighborhoods);
  const [airbnbLink, setAirbnbLink] = useState('');
  const [loading, setLoading] = useState(false); // Loading state
  const [tabIndex, setTabIndex] = useState(0); // State for tabs
  const [disableAirbnbLinkInput, setDisableAirbnbLinkInput] = useState(false);

  const Antioquia = 1;

  useEffect(() => {
    async function fetchData() {
      try {
        const cityResponse = await fetch(`${process.env.REACT_APP_API_URL}/Cities/GetCities/` + Antioquia);
        const cityData = await cityResponse.json();
        debugger
        setCities(cityData);
      } catch (err) {
        console.error('Error fetching data:', err);
        setError('Error al obtener datos.');
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    if (ciudad !== '') {
      getNeighborhoods(ciudad.id);
    }
  }, [ciudad]);

  const handleTabChange = (event, newValue) => {
    clearFields();
    setTabIndex(newValue);
  };

  const getNeighborhoods = async (cityId) => {
    const neighborhoodResponse = await fetch(`${process.env.REACT_APP_API_URL}/Neighborhoods/GetNeighborhoods/` + cityId);
    const neighborhoodData = await neighborhoodResponse.json();
    setNeighborhoods(neighborhoodData);
    setBarrio('');
  }

  const handleCiudadChange = async (e) => {
    setCiudad(e.target.value);
    const cityId = e.target.value.id;
    getNeighborhoods(cityId);
  };

  const validateFields = () => {
    const errors = [];
    if (tabIndex === 1) {
      if (!ciudad) errors.push('Ciudad es obligatorio.');
      if (!beds) errors.push('Tipo de propiedad es obligatorio.');
      if (!bedrooms) errors.push('Número de cuartos es obligatorio.');
      if (!baths) errors.push('Número de baños es obligatorio.');
      if (!barrio) errors.push('Barrio es obligatorio.');
      if (!guests) errors.push('Número de huespedes es obligatorio.');
    }
    else {
      if (!airbnbLink) errors.push('Link de Anuncio de Airbnb es obligatorio.');
      if (airbnbLink && !barrio) errors.push('Barrio es obligatorio.');
      if (barrio && !guests) errors.push('Número de huespedes es obligatorio.');
    }
    return errors;
  };

  const handleCalculateProfit = async (e) => {
    e.preventDefault();
    setError('');
    const errors = validateFields();
    if (errors.length > 0) {
      setError(errors.join(' '));
      return;
    }

    try {
      const selectedNeighborhood = neighborhoods.find((b) => b.name === barrio);

      const property =
      {
        "url": airbnbLink,
        "neighborhoodId": selectedNeighborhood.id,
        "cityId": ciudad.id,
        "guests": guests,
        "bedrooms": bedrooms,
        "beds": 1,
        "baths": baths,
        "nickname": nickname
      }
      const token = localStorage.getItem('token');

      const response = await fetch(`${process.env.REACT_APP_API_URL}/Properties/CalculateProfit`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(property)
      });

      const data = await response.json();
      setEstimatedValue(data.message);
      setNewProperty(property);

      setShowModal(true);
    } catch (err) {
      setError('Ocurrió un error al calcular la renta.');
    }
  };

  const clearFields = () => {
    setCiudad('');
    setBarrio('');
    setGuests('');
    setBedrooms('');
    setBaths('');
    setBeds('');
    setAirbnbLink('');
    setError('');
    setNickname('');
    setEstimatedValue(0);
  };

  const handleSaveProperty = () => {
    if (!newProperty) return;
    const token = localStorage.getItem('token');

    fetch(`${process.env.REACT_APP_API_URL}/Properties/Save`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      credentials: 'include',
      body: JSON.stringify(newProperty)
    })
      .then(response => {
        if (!response.ok) {
          // If the response status is not in the range 200-299, throw an error.
          throw new Error(`HTTP status ${response.status}`);
        }
        // Only attempt to parse JSON if there is content
        if (response.status === 201 || response.status === 204) {
          return; // No need to parse anything for status 201 or 204
        }

        return response.json(); // or response.text() if the response body is not JSON
      })
      .then(() => {
        toast.success('Propiedad guardada con éxito!');
        setNewProperty(null);
        setShowModal(false);
        clearFields();
      })
      .catch((error) => {
        if (error.message.includes('401')) {
          toast.error('Unauthorized! Please login again.');
        } else {
          toast.error('Error al guardar la propiedad.');
        }
      });

  };


  const handleCloseModal = () => {
    setShowModal(false);
  };

  const barriosFiltrados = neighborhoods
    ? neighborhoods.slice()
      .sort((a, b) => a.name.localeCompare(b.name))
    : [];

  const handleAnalizeAirBnbUrl = async () => {
    if (airbnbLink) {
      setDisableAirbnbLinkInput(true);
      setLoading(true); // Show loading indicator
      try {
        if (airbnbLink.startsWith("https://www.airbnb.com")) {

          const response = await fetch(`${process.env.REACT_APP_API_URL}/Properties/ExtractAirbnbData`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({ url: airbnbLink })
          });
          if (!response.ok) {
            const errorResponse = await response.json();
            throw new Error(errorResponse.message || 'Error al procesar el enlace de Airbnb.');
          }

          const data = await response.json();
          if (data.cityId) {
            if (data.cityId !== 0) {
              toast.success('Anuncio validado!');
              setCiudad(cities.find(city => city.id === data.cityId) || '');
              setGuests(data.guests);
              setBaths(data.baths);
              setBedrooms(data.bedrooms);
              setBeds(data.beds);
              setError('');
            } else {
              setError(`Todavía no trabajamos con ${data.city}`);
            }
          } else {
            setError('Error al procesar el enlace de Airbnb.');
          }
        }
        else {
          toast.error("Link invalido!");
          clearFields();
        }

      } catch (err) {
        // Display the error message from the caught error
        toast.error(err.message || 'Error al procesar el enlace de Airbnb.');
        clearFields();
      } finally {
        setLoading(false); // Hide loading indicator
        setDisableAirbnbLinkInput(false);
      }
    }
  };

  return (
    <Container maxWidth="sm" sx={{ textAlign: 'center', padding: 2 }}>
      <Card>
        <CardContent>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              mb: 3,
              backgroundColor: '#1B447E',
              padding: 2,
              borderRadius: 1,
            }}
          >
            <Typography
              variant="h6"
              gutterBottom
              sx={{
                color: '#FFFFFF',
              }}
            >
              Simula tus ganancias y registre tu propiedad!
            </Typography>
          </Box>

          {/* Tabs for switching between forms */}
          <form onSubmit={handleCalculateProfit}>

            <Tabs value={tabIndex} onChange={handleTabChange} centered>
              <Tab label="Registrar Link Airbnb" />
              <Tab label="Registrar Propiedad" />
            </Tabs>

            {tabIndex === 0 && (
              // Tab for Airbnb Link Registration

              <AirbnbLinkInput airbnbLink={airbnbLink}
                setAirbnbLink={setAirbnbLink}
                handleAnalizeAirBnbUrl={handleAnalizeAirBnbUrl}
                loading={loading}
                disabled={disableAirbnbLinkInput}
              ></AirbnbLinkInput>

            )}

            <PropertyRegistrationForm
              ciudad={ciudad}
              handleCiudadChange={handleCiudadChange}
              barrio={barrio}
              setBarrio={setBarrio}
              cities={cities}
              barriosFiltrados={barriosFiltrados}
              guests={guests}
              setGuests={setGuests}
              cuartos={bedrooms}
              setCuartos={setBedrooms}
              banos={baths}
              setBanos={setBaths}
              beds={beds}
              setBeds={setBeds}
              clearFields={clearFields}
              disabled={tabIndex === 0 ? true && ciudad === '' : false}
              nickname={nickname}
              setNickname={setNickname}
            />
            <Box sx={{ mt: 2 }}>
              <Grid container spacing={2} justifyContent="center">
                <Grid item xs={5}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                    sx={{ fontSize: '0.875rem' }}
                  >
                    Calcular Renta
                  </Button>
                </Grid>
                <Grid item xs={5}>
                  <Button
                    type="button"
                    variant="outlined"
                    onClick={clearFields}
                    fullWidth
                  >
                    Limpiar Formulario
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </form>

          {error && (
            <Alert severity="error" sx={{ mt: 2 }}>
              {error}
            </Alert>
          )}
        </CardContent>
      </Card>

      {/* Modal for showing the result */}
      <Modal open={showModal} onClose={handleCloseModal}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: !isNaN(estimatedValue) ? 600 : 400,
            bgcolor: 'background.paper',
            borderRadius: 2,
            boxShadow: 24,
            textAlign: 'center',
            p: 4,
          }}
        >
          {!isNaN(estimatedValue) ? (
            <>
              <Typography variant="h6" gutterBottom>
                Esa propriedad puede generarte diariamente hasta
              </Typography>

              <Typography variant="body1" gutterBottom component="span" sx={{ textAlign: 'center', color: 'green', fontSize: '2.5rem' }}>
                $ {estimatedValue.toLocaleString('es-CO') + ' COP'}
              </Typography>
            </>
          ) : (
            <Typography variant="body1" gutterBottom>
              {estimatedValue}
            </Typography>
          )}
          <br></br>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSaveProperty}
            sx={{ mt: 2, mr: 1 }}
          >
            Guardar Propiedad
          </Button>
          <Button variant="outlined" onClick={handleCloseModal} sx={{ mt: 2 }}>
            Cerrar
          </Button>
        </Box>
      </Modal>

      {/* Toast notification container */}
      <ToastContainer />
    </Container>
  );
}

export default RegisterProperty;
