import React, { useContext, useState } from 'react';
import { AppBar, Toolbar, IconButton, Typography, Menu, MenuItem, Avatar, Box } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu'; // Ensure this is imported
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';
import { AuthContext } from '../context/AuthContext';

const Navbar = () => {
  const [userMenuAnchorEl, setUserMenuAnchorEl] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const isMobile = useMediaQuery('(max-width:900px)');
  const { setIsAuthenticated, isAuthenticated } = useContext(AuthContext);
  const handleUserMenuOpen = (event) => setUserMenuAnchorEl(event.currentTarget);
  const handleUserMenuClose = () => setUserMenuAnchorEl(null);

  const handleLogout = () => {
    const token = localStorage.getItem('token');

    fetch(`${process.env.REACT_APP_API_URL}/Auth/logout`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
      .then(response => {
        if (response.ok) {
          console.log('Successfully logged out');
          localStorage.removeItem('user-info');
          localStorage.removeItem('token');
          setIsAuthenticated(false);
          navigate('/');
        }
      })
      .catch(error => console.error('Logout failed:', error));
  };

  const handleLogo = () => {
    navigate('/');
  };

  const avatarUrl = '/user-icon.png';
  const userInfo = JSON.parse(localStorage.getItem('user-info'));

  return (
    <AppBar position="static" sx={{ backgroundColor: "#1B447E" }}>
      <Toolbar sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <IconButton edge="start" color="inherit" aria-label="menu">
          {/* You can add any additional logo/icon here if needed */}
        </IconButton>

        <Typography variant="h6" sx={{ display: 'flex', alignItems: 'center' }}>
          <IconButton aria-label="logo" onClick={handleLogo}>
            <img src="/logo.png" alt="Logo" style={{ width: 40, height: 40, marginRight: 20 }} />
          </IconButton>
          <span style={{ color: '#F5C250' }}>|</span>
          <Link
            to="/register-property"
            style={{
              color: location.pathname === '/register-property' ? '#F5C250' : 'white',
              textDecoration: 'none',
              fontStyle: location.pathname === '/register-property' ? 'italic' : 'normal',
              marginRight: 20,
              marginLeft: 20,
              fontSize: isMobile ? '0.8rem' : '1rem'
            }}
          >
            Registrar Propiedad
          </Link>
          {isAuthenticated && (
            <>
              <span style={{ color: '#F5C250' }}>|</span>
              <Link
                to="/my-properties"
                style={{
                  color: location.pathname === '/my-properties' ? '#F5C250' : 'white',
                  textDecoration: 'none',
                  fontStyle: location.pathname === '/my-properties' ? 'italic' : 'normal',
                  marginLeft: 20,
                  marginRight: 20,
                  fontSize: isMobile ? '0.8rem' : '1rem'
                }}
              >
                Mis Propiedades
              </Link>
            </>
          )}
          <span style={{ color: '#F5C250' }}>|</span>
        </Typography>

        <Box display="flex" alignItems="center">
          <IconButton onClick={handleUserMenuOpen}>
            <MenuIcon sx={{ color: '#FFF', marginRight: 2 }} /> {/* Add MenuIcon here */}
            <Avatar sx={{ backgroundColor: '#F5C250', width: '30px', height: '30px' }} src={userInfo?.picture ?? avatarUrl} alt="User" />
          </IconButton>
          <Menu
            anchorEl={userMenuAnchorEl}
            open={Boolean(userMenuAnchorEl)}
            onClose={handleUserMenuClose}
            onClick={handleUserMenuClose}
          >
            {isAuthenticated ? (
              [
                <MenuItem key="profile" onClick={() => navigate('/profile')}>Perfil</MenuItem>,
                <MenuItem key="logout" onClick={handleLogout}>Cerrar Sesión</MenuItem>
              ]
            ) : (
              [
                <MenuItem key="login" onClick={() => navigate('/login')}>Iniciar Sesión</MenuItem>,
                <MenuItem key="signup" onClick={() => navigate('/signup')}>Crear Cuenta Nueva</MenuItem>
              ]
            )}
            <hr />
            <MenuItem key="about-us" onClick={() => navigate('/')}>Quién somos nosotros</MenuItem>
            <MenuItem key="manage-ads" onClick={() => navigate('/manage-ads')}>Manejar Anuncio</MenuItem>
            <MenuItem key="manage-ads-and-properties" onClick={() => navigate('/manage-ads-and-properties')}>Manejar Anuncio + Propiedad</MenuItem>
            <MenuItem key="budget" onClick={() => navigate('/budget')}>Qué incluye nuestro Presupuesto</MenuItem>
          </Menu>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
