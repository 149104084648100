import { toast } from 'react-toastify';
import React, { useContext } from 'react';
import { GoogleLogin } from '@react-oauth/google';
import { useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { AuthContext } from '../context/AuthContext';

const LoginWithGoogle = () => {
  const { setIsAuthenticated } = useContext(AuthContext);
  const navigate = useNavigate();
  const onSuccess = (response) => {
    toast.success('Login éxitoso!');
    const token = response.credential;

    fetch(`${process.env.REACT_APP_API_URL}/Auth/google`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',        
      },
      body: JSON.stringify({ token }),
      credentials: 'include'
    })
      .then(response => {
        setIsAuthenticated(response.ok);
        return response.json();
      })
      .then((data) => {
        localStorage.setItem('token', data.token);
        localStorage.setItem('user-info', data.userInfo);
        navigate('/');
      })
      .catch(error => {
        console.error("Error checking auth status:", error);
        setIsAuthenticated(false);
        localStorage.removeItem('user-info');
        localStorage.removeItem('token');
      });
  };

  const onFailure = (response) => {
    console.log('Login Failed:', response);
  };

  return (
    <>
      <div>
        <GoogleLogin
          clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
          buttonText="Login with Google"
          onSuccess={onSuccess}
          onFailure={onFailure}
          cookiePolicy={'single_host_origin'}
        />
      </div>
      <br></br>

      <ToastContainer />
    </>
  );
};

export default LoginWithGoogle;
