// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import RegisterProperty from './pages/properties/Register';
import Login from './pages/Login';
import ForgotPassword from './pages/ForgotPassword';
import Signup from './pages/Signup';
import Home from './pages/Home';
import ListProperties from './pages/properties/List';
import PropertyDetails from './pages/properties/PropertyDetails';
import PrivateRoute from './components/PrivateRoute';
import ManageAdsPage from './pages/ManageAdsPage';
import PrivacyPolicy from './pages/PrivacyPolicy';
import ManageAdsAndPropertiesPage from './pages/ManageAdsAndPropertiesPage';
import PaymentSelectionPage from './pages/payment/AvailableMethods';
import CreditCardForm from './pages/payment/CreditCard';
import Budget from './pages/Budget';
import ScrollToTop from './components/ScrollToTop';
import TermsOfService from './pages/TermsOfService';
import { Contact } from './components/Home';
import ProfilePage from './pages/Profile';
import { AuthProvider } from './context/AuthContext'; // Import AuthProvider
import AccessDenied from './pages/AccessDenied';
import ScheduleConfirmation from './components/ScheduleConfirmation';
import Pse from './pages/payment/Pse';

const App = () => {
  return (
    <AuthProvider> {/* Wrap entire app with AuthProvider */}
      <Router>
        <Navbar />
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/access-denied" element={<AccessDenied />} />
          <Route path="/budget" element={<Budget />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/login" element={<Login />} />
          <Route path="/manage-ads" element={<ManageAdsPage />} />
          <Route path="/manage-ads-and-properties" element={<ManageAdsAndPropertiesPage />} />
          <Route path="/my-properties" element={<PrivateRoute><ListProperties /></PrivateRoute>} />
          <Route path="/payment-selection" element={<PrivateRoute><PaymentSelectionPage /></PrivateRoute>} />
          <Route path="/payment/credit-card" element={<PrivateRoute><CreditCardForm /></PrivateRoute>} />
          <Route path="/payment/pse" element={<PrivateRoute><Pse /></PrivateRoute>} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/profile" element={<PrivateRoute><ProfilePage /></PrivateRoute>} />
          <Route path="/property/:id" element={<PrivateRoute><PropertyDetails /></PrivateRoute>} />
          <Route path="/register-property" element={<RegisterProperty />} />
          <Route path="/schedule-confirmation" element={<ScheduleConfirmation />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/terms-of-service" element={<TermsOfService />} />
        </Routes>
        <br></br>
        <Footer />
      </Router>
    </AuthProvider>
  );
};

export default App;
