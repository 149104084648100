import React, { useState } from 'react';
import { Container, TextField, Button, Box, Typography, Alert } from '@mui/material';
import { useNavigate } from 'react-router-dom';

function ForgotPassword() {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate(); // Importa useNavigate para navegação

  const handleSend = async (e) => {
    e.preventDefault();
    setError('');

    // Simula envio de email para recuperação
    try {
      // const response = await fetch('https://api.externa.com/recovery', {
      //   method: 'POST',
      //   headers: { 'Content-Type': 'application/json' },
      //   body: JSON.stringify({ email }),
      // });

      // const data = await response.json();
      // if (response.ok) {
      //   // Supondo que a resposta esteja ok
      // } else {
      //   setError('Ocurrió un error al enviar el correo.');
      // }

      // Exemplo simples de simulação
      console.log(`Enviar recuperação para: ${email}`);
      // Navega para tela anterior ou de sucesso
      navigate('/login'); // Navega para a página de login após enviar
    } catch (err) {
      setError('Ocurrió un error al intentar recuperar la contraseña.');
    }
  };

  return (
    <Container maxWidth="xs" sx={{ mt: 5 }}>
      <Typography variant="h5" sx={{ mb: 2, textAlign: 'center' }}>
        Recuperar Contraseña
      </Typography>
      {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}
      <form onSubmit={handleSend}>
        <TextField
          label="Correo Electrónico"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          fullWidth
          required
          margin="normal"
        />
        <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between' }}>
          <Button variant="contained" color="error" onClick={() => navigate('/login')}>
            Cancelar
          </Button>
          <Button type="submit" variant="contained" color="success">
            Enviar
          </Button>
        </Box>
      </form>
    </Container>
  );
}

export default ForgotPassword;
