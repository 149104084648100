import React from 'react';
import { Box, Container, Typography, TextField, Button, IconButton } from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

const Contact = () => (
  <Box id="contact" sx={{ py: 5, backgroundColor: '#f8f9fa', position: 'relative' }}>
    <Container maxWidth="sm">
      <Typography variant="h4" align="center" gutterBottom sx={{ fontWeight: 'bold' }}>
        Contáctanos
      </Typography>
      <Box
        component="form"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          maxWidth: '600px',
          mx: 'auto',
          backgroundColor: '#ffffff',
          borderRadius: 2,
          boxShadow: 3,
          p: 3
        }}
      >
        <TextField
          fullWidth
          label="Nombre"
          margin="normal"
          variant="outlined"
        />
        <TextField
          fullWidth
          label="Email"
          margin="normal"
          variant="outlined"
          type="email"
        />
        <TextField
          fullWidth
          label="Mensaje"
          margin="normal"
          multiline
          rows={4}
          variant="outlined"
        />
        <Button
          type="submit"
          variant="contained"
          color="primary"
          fullWidth
          sx={{
            mt: 2,
            backgroundColor: '#007bff',
            '&:hover': {
              backgroundColor: '#0056b3'
            }
          }}
        >
          Enviar
        </Button>
      </Box>

      {/* Social Media Icons */}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          mt: 4,
          gap: 2
        }}
      >
        <IconButton
          component="a"
          href="https://www.facebook.com/piso7.co/"
          target="_blank"
          rel="noopener"
          aria-label="Facebook"
          sx={{
            position: 'relative',
            color: '#3b5998',
            '&:hover': {
              color: '#F5C250',
              '&:before': {
                content: '""',
                position: 'absolute',
                top: '50%',
                left: '50%',
                width: '36px', // Adjust size as needed
                height: '36px', // Adjust size as needed
                borderRadius: '50%',
                backgroundColor: '#e0e0e0', // Gray circle
                transform: 'translate(-50%, -50%)',
                zIndex: -1
              }
            },
            transition: 'color 0.3s ease'
          }}
        >
          <FacebookIcon fontSize="large" />
        </IconButton>
        <IconButton
          component="a"
          href="https://www.instagram.com/piso7.co/"
          target="_blank"
          rel="noopener"
          aria-label="Instagram"
          sx={{
            position: 'relative',
            color: '#e4405f',
            '&:hover': {
              color: '#F5C250',
              '&:before': {
                content: '""',
                position: 'absolute',
                top: '50%',
                left: '50%',
                width: '36px', // Adjust size as needed
                height: '36px', // Adjust size as needed
                borderRadius: '50%',
                backgroundColor: '#e0e0e0', // Gray circle
                transform: 'translate(-50%, -50%)',
                zIndex: -1
              }
            },
            transition: 'color 0.3s ease'
          }}
        >
          <InstagramIcon fontSize="large" />
        </IconButton>
        <IconButton
          component="a"
          href="https://www.linkedin.com/company/piso7/about/"
          target="_blank"
          rel="noopener"
          aria-label="LinkedIn"
          sx={{
            position: 'relative',
            color: '#0e76a8',
            '&:hover': {
              color: '#F5C250',
              '&:before': {
                content: '""',
                position: 'absolute',
                top: '50%',
                left: '50%',
                width: '36px', // Adjust size as needed
                height: '36px', // Adjust size as needed
                borderRadius: '50%',
                backgroundColor: '#e0e0e0', // Gray circle
                transform: 'translate(-50%, -50%)',
                zIndex: -1
              }
            },
            transition: 'color 0.3s ease'
          }}
        >
          <LinkedInIcon fontSize="large" />
        </IconButton>
      </Box>
    </Container>
  </Box>
);

export default Contact;
