import React, { useState, useEffect } from 'react';
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  TablePagination,
  TableFooter,
  IconButton,
  Box,
  Tooltip,
  Grid,
  Paper,
  Snackbar
} from '@mui/material';
import { Delete, Visibility } from '@mui/icons-material';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import { StyledTableRow, StyledTableCell, StyledTableHeaderCell } from '../../styles/TableStyles';
import { deleteProperty, getProperties } from '../../utils/propertyUtils';
import DeleteModal from '../../components/DeleteModal';

const ListProperties = () => {
  const [properties, setProperties] = useState([]);
  const [orderDirection, setOrderDirection] = useState('asc');
  const [orderBy, setOrderBy] = useState('cityId');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [showModal, setShowModal] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [loading, setLoading] = useState(true); // Loading state
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true); // Start loading
      const data = await getProperties();
      setProperties(data);
      setLoading(false); // End loading
    };
    fetchData();
  }, []);

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && orderDirection === 'asc';
    setOrderDirection(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleDetailClick = (estimate) => {
    navigate(`/property/${estimate.id}`);
  };

  const handleDeleteClick = (id) => {
    setDeleteId(id);
    setShowModal(true);
  };

  const handleConfirmDelete = async () => {
    try {
      deleteProperty(deleteId);
      const data = await getProperties();
      setProperties(data);
      setDeleteId(null);
      setShowModal(false);
      toast.success('Propiedad eliminada con éxito!');
      setSnackbarOpen(true);
    } catch (error) {
      toast.error('Error al intentar eliminar la propiedad.');
      setSnackbarOpen(true);
    }
  };

  const handleCancelDelete = () => {
    setDeleteId(null);
    setShowModal(false);
  };

  const sortedEstimates = properties ? properties.slice().sort((a, b) => {
    const aValue = orderBy.includes('.') ? a[orderBy.split('.')[0]][orderBy.split('.')[1]] : a[orderBy];
    const bValue = orderBy.includes('.') ? b[orderBy.split('.')[0]][orderBy.split('.')[1]] : b[orderBy];

    if (orderDirection === 'asc') {
      return aValue < bValue ? -1 : 1;
    } else {
      return aValue > bValue ? -1 : 1;
    }
  }) : [];

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  return (
    <Box sx={{ width: '90%', padding: 2, margin: '0 auto' }}>
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={12}>
          <Paper elevation={3}>
            <TableContainer>
              {loading ? (
                <Box display="flex" justifyContent="center" alignItems="center" height="300px">
                  <img src="/loading.gif" alt="Loading..." />
                </Box>
              ) : (
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      <StyledTableHeaderCell>
                        <TableSortLabel
                          active={orderBy === 'neighborhoodId'}
                          direction={orderBy === 'neighborhoodId' ? orderDirection : 'asc'}
                          onClick={() => handleRequestSort('neighborhoodId')}
                        >
                          Apodo
                        </TableSortLabel>
                      </StyledTableHeaderCell>
                      <StyledTableHeaderCell>
                        <TableSortLabel
                          active={orderBy === 'cityId'}
                          direction={orderBy === 'cityId' ? orderDirection : 'asc'}
                          onClick={() => handleRequestSort('cityId')}
                        >
                          Local
                        </TableSortLabel>
                      </StyledTableHeaderCell>
                      <StyledTableHeaderCell>Acciones</StyledTableHeaderCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {sortedEstimates
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((property) => (
                        <StyledTableRow key={property.id}>
                          <StyledTableCell>{property.nickname}</StyledTableCell>
                          <StyledTableCell>{property.cityId}</StyledTableCell>
                          <StyledTableCell>
                            <Tooltip title="Ver Detalles">
                              <IconButton
                                color="primary"
                                onClick={() => handleDetailClick(property)}
                              >
                                <Visibility />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="Eliminar">
                              <IconButton
                                color="secondary"
                                onClick={() => handleDeleteClick(property.id)}
                                sx={{ color: 'red' }}
                                aria-label="Eliminar"
                              >
                                <Delete />
                              </IconButton>
                            </Tooltip>
                          </StyledTableCell>
                        </StyledTableRow>
                      ))}
                  </TableBody>
                  <TableFooter>
                    <TableRow>
                      <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        count={properties.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                      />
                    </TableRow>
                  </TableFooter>
                </Table>
              )}
            </TableContainer>
          </Paper>
        </Grid>
      </Grid>
      <DeleteModal
        open={showModal}
        onClose={handleCancelDelete}
        onConfirm={handleConfirmDelete}
      />
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      />
      <ToastContainer position="top-right" autoClose={5000} hideProgressBar newestOnTop closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
    </Box>
  );
};

export default ListProperties;
