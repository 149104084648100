import React from 'react';
import { Grid, Typography, Box } from '@mui/material';

const PropertyData = ({ property }) => (
  <Box sx={{ mb: 2 }}>
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <Typography variant="body1">
          <strong>Ciudad:</strong> {property.city}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Typography variant="body1">
          <strong>Barrio:</strong> {property.neighborhood}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Typography variant="body1">
          <strong>Cuartos:</strong> {property.bedrooms}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Typography variant="body1">
          <strong>Baños:</strong> {property.baths}
        </Typography>
      </Grid>
    </Grid>
  </Box>
);

export default PropertyData;
