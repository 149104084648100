import React, { useState } from 'react';
import { Container, TextField, Button, Box, Typography, Alert } from '@mui/material';
import { useNavigate } from 'react-router-dom';

function Signup() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleSignup = async (e) => {
    e.preventDefault();
    setError('');

    if (password !== confirmPassword) {
      setError('Las contraseñas no coinciden.');
      return;
    }

    try {
      // Supondo que haja uma API para criação de conta
      // const response = await fetch('https://api.externa.com/signup', {
      //   method: 'POST',
      //   headers: { 'Content-Type': 'application/json' },
      //   body: JSON.stringify({ email, password }),
      // });
      // if (response.ok) {
      //   navigate('/login');
      // } else {
      //   setError('No se pudo crear la cuenta.');
      // }

      // Código de exemplo para simular criação de conta
      if (email === 'nuevo@example.com' && password === 'password') {
        navigate('/login');
      } else {
        setError('No se pudo crear la cuenta.');
      }
    } catch (err) {
      setError('Ocurrió un error al intentar crear la cuenta.');
    }
  };

  return (
    <Container maxWidth="xs" sx={{ mt: 5 }}>
      <Typography variant="h5" sx={{ mb: 2, textAlign: 'center' }}>
        Crear Cuenta Nueva
      </Typography>
      {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}
      <form onSubmit={handleSignup}>
        <TextField
          label="Correo Electrónico"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          fullWidth
          required
          margin="normal"
        />
        <TextField
          label="Contraseña"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          fullWidth
          required
          margin="normal"
        />
        <TextField
          label="Confirmar Contraseña"
          type="password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          fullWidth
          required
          margin="normal"
        />
        <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between' }}>
          <Button variant="contained" color="error" onClick={() => navigate('/login')}>
            Cancelar
          </Button>
          <Button type="submit" variant="contained" color="success">
            Crear Cuenta
          </Button>
        </Box>
      </form>
    </Container>
  );
}

export default Signup;
