import React, { useState } from 'react';
import { Box, Button, Typography, IconButton, Modal } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import LaunchIcon from '@mui/icons-material/Launch';

const ScheduleSection = ({ schedule, onCancelMeeting, propertyId }) => {
  const [openModal, setOpenModal] = useState(false);

  const onScheduleCallClick = () => {
    setOpenModal(true);
    localStorage.setItem("propertyId", propertyId);
  };

  // Function to close the modal
  const closeModal = () => {
    setOpenModal(false);
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 2 }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
        {schedule ? (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              padding: 2,
              borderRadius: 2,
              border: '1px solid #d0d0d0',
              backgroundColor: '#f5f5f5',
              width: '100%',
              maxWidth: 400,
              position: 'relative',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                textAlign: 'center',
                gap: 1,
              }}
            >
              <Typography variant="body2" sx={{ fontSize: '0.875rem' }}>
                ¡Video Llamada Agendada!
              </Typography>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5, marginTop: 1 }}>
                <b>{schedule.date}</b>&nbsp;a las&nbsp;<b>{schedule.time}</b>&nbsp;&nbsp;&nbsp;
                <a
                  href='https://meet.google.com/aco-yapb-rdf'
                  target='_blank'
                  rel='noopener noreferrer'
                  style={{ display: 'flex', alignItems: 'center', textDecoration: 'none', color: '#007bff' }}
                >
                  <Typography sx={{ fontSize: '0.875rem', marginRight: 0.5 }}>Access the Room</Typography>
                  <LaunchIcon fontSize="small" />
                </a>
              </Box>
            </Box>
            <IconButton
              onClick={onCancelMeeting}
              sx={{ color: 'grey.500', position: 'absolute', top: 8, right: 8 }}
            >
              <CloseIcon />
            </IconButton>
          </Box>
        ) : (
          <Button variant="contained" color="secondary" onClick={onScheduleCallClick}>
            Agendar Video Llamada
          </Button>
        )}

        {/* Modal for Calendly */}
        <Modal
          open={openModal}
          onClose={closeModal}
          sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
        >
          <Box sx={{
            width: '90%',
            maxWidth: '700px', // Increase maxWidth to make the modal wider
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
            position: 'relative',
          }}>
            <IconButton
              onClick={closeModal}
              sx={{ position: 'absolute', top: 16, right: 16, color: 'grey.500' }}
            >
              <CloseIcon />
            </IconButton>
            <iframe
              title="Calendly Scheduling"
              src={process.env.REACT_APP_CALENDLY_URL}
              width="100%"
              height="600"
              frameBorder="0"
              scrolling="no"
              style={{ border: 'none' }}
            />
          </Box>
        </Modal>
      </Box>
    </Box>
  );
};

export default ScheduleSection;
