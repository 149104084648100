import { useLocation } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

// Helper function to parse query string
/*
  assigned_to=Stephan%20Figueredo%20Spacov
  &event_type_uuid=af0e7d1f-361c-4e3a-93e5-5b31ab52dc83
  &event_type_name=Piso%207%3A%20Reunión%20de%201%20hora
  &event_start_time=2024-10-10T10%3A00%3A00-05%3A00
  &event_end_time=2024-10-10T11%3A00%3A00-05%3A00
  &invitee_uuid=a5c2095f-9b14-4c7f-95ea-52067710ea7f
  &invitee_full_name=Ze%20Depolier
  &invitee_email=zezao%40gmail.com
*/
const ScheduleConfirmation = () => {
  // Get the query parameters
  const query = new URLSearchParams(useLocation().search);
  const name = query.get("invitee_full_name");
  const email = query.get("invitee_email");
  const event_start_time = query.get("event_start_time");
  const event_end_time = query.get("event_end_time");
  const propertyId = localStorage.getItem("propertyId");

  // Clear the propertyId from local storage
  localStorage.removeItem("propertyId");
  
  const navigate = useNavigate(); 
  
  useEffect(() => {
    // Redirect to the desired page
    if (propertyId) {
      navigate(`/property/${propertyId}?name=${name}&email=${email}&event_start_time=${event_start_time}&event_end_time=${event_end_time}`);
    }
  }, [navigate, propertyId, name, email, event_start_time, event_end_time]);

  return null; // Optionally, you can return a loading spinner or a message here.
};

export default ScheduleConfirmation;
